import React from "react";
import PropTypes from "prop-types";
import jwtDecode from "jwt-decode";
import { Route, Redirect } from "react-router-dom";

const Authmiddleware = ({ component: Component, isAuthProtected, ...rest }) => (
  <Route
    exact
    // {...rest}

    render={(props) => {
      // if (isAuthProtected && !localStorage.getItem("token")) {
      //   return (
      //     <Redirect
      //       to={{ pathname: "/home", state: { from: props.location } }}
      //     />
      //   );
      // }

      // if (!isAuthProtected && localStorage.getItem("token")) {
      //   return (
      //     <Redirect
      //       to={{ pathname: "/home", state: { from: props.location } }}
      //     />
      //   );
      // }

      // if (isAuthProtected && localStorage.getItem("token")) {
      //   const jwt_Token_decoded = jwtDecode(localStorage.getItem("token"));

      //   if (jwt_Token_decoded.exp * 1000 < Date.now()) {
      //     localStorage.clear();
      //   }
      // }

      return <Component {...props} />;
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
};

export default Authmiddleware;
