import {
  UPDATE_ADDRESS,
  SET_ADDRESS_ERRORS,
  RESET_ADDRESS,
  ADD_ADDRESS,
  DELETE_ADDRESS,
  EDIT_ADDRESS,
} from '../types';

const initialState = [];

const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ADDRESS: {
      return [...state, action.payload];
    }
    case EDIT_ADDRESS: {
      let addressIndex = state.findIndex(
        (address) => address._id === action.payload._id
      );
      if (addressIndex !== -1) {
        let newAddress = [...state];
        newAddress[addressIndex] = action.payload;
        return newAddress;
      }
    }
    case DELETE_ADDRESS: {
      return state.filter(
        (address) => address._id !== action.payload.addressId
      );
    }
    case UPDATE_ADDRESS: {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }
    case SET_ADDRESS_ERRORS: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case RESET_ADDRESS:
      return initialState;
    default:
      return state;
  }
};

export default addressReducer;
