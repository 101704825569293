import {
  FETCH_SERVICES,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICES_ERROR,
  UPDATE_SERVICES,
  UPDATE_SERVICES_SUCCESS,
  UPDATE_SERVICES_ERROR,
  ADD_SERVICES,
  ADD_SERVICES_SUCCESS,
  ADD_SERVICES_ERROR,
  DELETE_SERVICES,
  DELETE_SERVICES_SUCCESS,
  DELETE_SERVICES_ERROR,
  SERVICES_ERROR_REMOVE,
} from "../types";

import _ from "lodash";

const initialState = {
  type: null,
  error: null,
  list: [],
  sortBy: "ordering",
  sortDir: -1,
  filters: {},
};

export function setErrosAction(dispatch, data) {
  dispatch(data);
}

export default (state = initialState, action) => {
  let list;
  switch (action.type) {
    case FETCH_SERVICES:
      return {
        ...state,
        ...action,
        error: null,
      };
      break;
    case FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        ...action,
        error: null,
      };
      break;
    case FETCH_SERVICES_ERROR:
      return {
        ...state,
        ...action,
      };
      break;

    case UPDATE_SERVICES:
      return {
        ...state,
        ...action,
        error: null,
      };
      break;
    case UPDATE_SERVICES_SUCCESS:
      list = _.cloneDeep(state.list);
      let i = _.findIndex(list.data, (item) => {
        return item._id == action.payload._id;
      });
      list.data[i] = action.payload;
      return {
        ...state,
        list: list,
        error: null,
      };
      break;
    case UPDATE_SERVICES_ERROR:
      return {
        ...state,
        ...action,
      };
      break;
    case ADD_SERVICES:
      return {
        ...state,
        ...action,
        error: null,
      };
      break;
    case ADD_SERVICES_SUCCESS:
      list = _.cloneDeep(state.list);
      list.data.unshift(action.payload);
      return {
        ...state,
        list: list,
        error: null,
      };
      break;
    case ADD_SERVICES_ERROR:
      return {
        ...state,
        ...action,
      };
      break;
    case DELETE_SERVICES:
      return {
        ...state,
        ...action,
        error: null,
      };
      break;
    case DELETE_SERVICES_SUCCESS:
      list = _.cloneDeep(state.list);
      _.remove(list.data, (item) => {
        return item._id == action.payload._id;
      });

      return {
        ...state,
        list: list,
        error: null,
      };
      break;
    case DELETE_SERVICES_ERROR:
      return {
        ...state,
        ...action,
      };
      break;
    case SERVICES_ERROR_REMOVE:
      return {
        ...state,
        ...action,
      };
      break;

    default:
      return state;
  }
};
