import React from 'react';
import { Redirect } from 'react-router-dom';

// Pages
const Home = React.lazy(() => import('../components/pages/Home'));
const PartnerWithUs = React.lazy(() => import('../components/pages/PartnerWithUs'));
const FreeQuoteForm = React.lazy(() => import('../components/pages/FreeQuoteForm'));
const HelpCenter = React.lazy(() => import('../components/pages/HelpCenter'));
const TermsAndConditions = React.lazy(() => import('../components/pages/TermsAndConditions'));
const LuxuryServices = React.lazy(() => import('../components/pages/LuxuryServices'));
const TrackingOrder = React.lazy(() => import('../components/pages/TrackingOrder'));
const ThankYou = React.lazy(() => import('../components/pages/ThankYou'));
const HowItWorks = React.lazy(() => import('../components/pages/HowItWorks'));
const About = React.lazy(() => import('../components/pages/About'));
const Login = React.lazy(() => import('../components/pages/Login'));
const Signup = React.lazy(() => import('../components/pages/Signup'));
const ForgotPassword = React.lazy(() => import('../components/pages/ForgotPassword'));
const Verification = React.lazy(() => import('../components/pages/Verification'));
const Registration = React.lazy(() => import('../components/pages/Registration'));
const Serviceone = React.lazy(() => import('../components/pages/Serviceone'));
const Servicedetails = React.lazy(() => import('../components/pages/Service_details'));
const Summary = React.lazy(() => import('../components/pages/Summary'));
const Payment = React.lazy(() => import('../components/pages/Payment'));
const Account = React.lazy(() => import('../components/pages/Account'));
// const Hometwo = React.lazy(() => import('../components/pages/Hometwo'));
// const Homethree = React.lazy(() => import('../components/pages/Homethree'));
// const Homefour = React.lazy(() => import('../components/pages/Homefour'));
// const Bloglistgridone = React.lazy(() => import('../components/pages/Bloglistgridone'));
// const Bloglistgridtwo = React.lazy(() => import('../components/pages/Bloglistgridtwo'));
// const Bloglistgridsidebar = React.lazy(() => import('../components/pages/Bloglistgridsidebar'));
// const Blogliststandard = React.lazy(() => import('../components/pages/Blogliststandard'));
// const Blogliststandardleft = React.lazy(() => import('../components/pages/Blogliststandardleft'));
// const Blogliststandardnosidebar = React.lazy(() => import('../components/pages/Blogliststandardnosidebar'));
// const Blogdetails = React.lazy(() => import('../components/pages/Blogdetails'));
// const Blogdetailsvideo = React.lazy(() => import('../components/pages/Blogdetailsvideo'));
// const Blogdetailsslider = React.lazy(() => import('../components/pages/Blogdetailsslider'));
// const Blogdetailsnosidebar = React.lazy(() => import('../components/pages/Blogdetailsnosidebar'));
// const Portfoliocolthree = React.lazy(() => import('../components/pages/Portfoliocolthree'));
// const Portfoliocolfour = React.lazy(() => import('../components/pages/Portfoliocolfour'));
// const Portfoliomasonary = React.lazy(() => import('../components/pages/Portfoliomasonary'));
// const Portfoliodetails = React.lazy(() => import('../components/pages/Portfoliodetails'));
// const Pricingone = React.lazy(() => import('../components/pages/Pricingone'));
// const Pricingtwo = React.lazy(() => import('../components/pages/Pricingtwo'));
// const Shop = React.lazy(() => import('../components/pages/Shop'));
// const Shopcolfour = React.lazy(() => import('../components/pages/Shopcolfour'));
// const Productdetails = React.lazy(() => import('../components/pages/Productdetails'));
// const Cart = React.lazy(() => import('../components/pages/Cart'));
// const Checkout = React.lazy(() => import('../components/pages/Checkout'));
//const Servicetwo = React.lazy(() => import('../components/pages/Servicetwo'));
// const Teamone = React.lazy(() => import('../components/pages/Teamone'));
// const Teamtwo = React.lazy(() => import('../components/pages/Teamtwo'));
// const Teamdetails = React.lazy(() => import('../components/pages/Teamdetails'));
// const Testimonials = React.lazy(() => import('../components/pages/Testimonials'));
// const Clients = React.lazy(() => import('../components/pages/Clients'));
// const Comingsoon = React.lazy(() => import('../components/pages/Comingsoon'));
// const Costcalculator = React.lazy(() => import('../components/pages/Costcalculator'));
// const Error404 = React.lazy(() => import('../components/pages/Error404'));
// const Faqs = React.lazy(() => import('../components/pages/Faqs'));
// const Gallerytwo = React.lazy(() => import('../components/pages/Gallerytwo'));
// const Gallerythree = React.lazy(() => import('../components/pages/Gallerythree'));
// const Galleryfour = React.lazy(() => import('../components/pages/Galleryfour'));
// const Galleryafterbefore = React.lazy(() => import('../components/pages/Galleryafterbefore'));
// const Galleryafterbeforetwo = React.lazy(() => import('../components/pages/Galleryafterbeforetwo'));
// const Contact = React.lazy(() => import('../components/pages/Contact'));

const authRoutes = [
  //Master
  { path: '/home', exact: true, component: Home },
  { path: '/partner-with-us', component: PartnerWithUs },
  { path: '/free-quote-form', component: FreeQuoteForm },
  { path: '/help-center', component: HelpCenter },
  { path: '/terms-and-conditions', component: TermsAndConditions},
  { path: '/luxuryservices', component: LuxuryServices },
  { path: '/trackingorder', component: TrackingOrder },
  { path: '/thankyou', component: ThankYou },
  { path: '/how-it-works', component: HowItWorks },
  { path: '/about', component: About },
  { path: '/login', component: Login },
  { path: '/signup', component: Signup },
  { path: '/forgot-password', component: ForgotPassword },
  { path: '/verification', component: Verification },
  { path: '/registration', component: Registration },
  { path: '/services', component: Serviceone },
  { path: '/service-details/:id', component: Servicedetails },
  { path: '/summary', component: Summary },
  { path: '/payment', component: Payment },
  { path: '/account', component: Account },
  // { path: '/home-v2', component: Hometwo },
  // { path: '/home-v3', component: Homethree },
  // { path: '/home-v4', component: Homefour },
  // { path: '/blog-list-grid-v1', component: Bloglistgridone },
  // { path: '/blog-list-grid-v2', component: Bloglistgridtwo },
  // { path: '/blog-list-grid-sidebar', component: Bloglistgridsidebar },
  // { path: '/blog-list-standared', component: Blogliststandard },
  // { path: '/blog-list-standared-left-sidebar',  component: Blogliststandardleft, },
  // { path: '/blog-list-standared-no-sidebar', component: Blogliststandardnosidebar, },
  // { path: '/blog-details', component: Blogdetails },
  // { path: '/blog-details-video', component: Blogdetailsvideo },
  // { path: '/blog-details-slider', component: Blogdetailsslider },
  // { path: '/blog-details-nosidebar', component: Blogdetailsnosidebar },
  // { path: '/portfolio-col-3', component: Portfoliocolthree },
  // { path: '/portfolio-col-4', component: Portfoliocolfour },
  // { path: '/portfolio-masonry', component: Portfoliomasonary },
  // { path: '/portfolio-details', component: Portfoliodetails },
  // { path: '/pricing-v1', component: Pricingone },
  // { path: '/pricing-V2', component: Pricingtwo },
  // { path: '/shop', component: Shop },
  // { path: '/shop-col-4', component: Shopcolfour },
  // { path: '/product-details', component: Productdetails },
  // { path: '/cart', component: Cart },
  // { path: '/checkout', component: Checkout },
  // { path: '/services-v2', component: Servicetwo },
  // { path: '/team-v1', component: Teamone },
  // { path: '/team-v2', component: Teamtwo },
  // { path: '/team-details', component: Teamdetails },
  // { path: '/clients', component: Clients },
  // { path: '/testimonials', component: Testimonials },
  // { path: '/faq', component: Faqs },
  // { path: '/coming-soon', component: Comingsoon },
  // { path: '/cost-calculator', component: Costcalculator },
  // { path: '/gallery-V2', component: Gallerytwo },
  // { path: '/gallery-V3', component: Gallerythree },
  // { path: '/gallery-V4', component: Galleryfour },
  // { path: '/gallery-after-before', component: Galleryafterbefore },
  // { path: '/gallery-after-before-2col', component: Galleryafterbeforetwo },
  // { path: '/contact', component: Contact },
  // { path: '/error', component: Error404 },



  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to='/home' /> },

];

const userRoutes = [];

export { userRoutes, authRoutes };
