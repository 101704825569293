import {
  ADD_SERVICE_ITEM_TO_CART,
  ADD_SERVICE_ITEM_TO_CART_SUCCESS,
  ADD_SERVICE_ITEM_TO_CART_ERROR,
  REMOVE_SERVICE_ITEM_FROM_CART,
  REMOVE_SERVICE_ITEM_FROM_CART_SUCCESS,
  REMOVE_SERVICE_ITEM_FROM_CART_ERROR,
  CART_ERROR_REMOVE,
  MARK_SERVICE_AS_SELECTED,
  CREATE_OR_UPDATE_CART_ID,
  APPLY_COUPON_CODE,
  APPLY_COUPON_CODE_ERROR,
  UPDATE_TOTAL_PAYABLE,
  CLEAR_CART,
  RUSH_DELIVERY_OPTIONS,
  UPDATE_SERVICE_NOTE,
} from '../types';

const initialState = {
  type: null,
  error: null,
  services: [],
  appliedCoupon: null,
  order_total: 0,
  rushDeliveryOptions: null,
};

export function setErrosAction(dispatch, data) {
  dispatch(data);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_SERVICE_ITEM_TO_CART:
      return {
        ...state,
        ...action,
        error: null,
      };
      break;

    case ADD_SERVICE_ITEM_TO_CART_SUCCESS: {
      let services = state.services;

      const serviceExist = services.findIndex(
        (x) => x._id === action.payload._id
      );

      if (serviceExist > -1) {
        let service = services[serviceExist];

        // remove temp item from cart when real item is added
        let items = action.payload.serviceItem?.temp
          ? service?.items
          : service?.items?.filter((item) => item?.temp !== true);

        const serviceItemExist = items.find(
          (x) => x?._id === action.payload?.serviceItem?._id
        );

        if (serviceItemExist) {
          items = items.map((x) =>
            x._id === action.payload.serviceItem._id
              ? {
                  ...serviceItemExist,
                  quantity: serviceItemExist.quantity + 1,
                }
              : x
          );
        } else items.push({ ...action.payload.serviceItem, quantity: 1 });

        service.items = items;
        services[serviceExist] = service;
      } else {
        let service = action.payload;
        let serviceItem = service.serviceItem;

        delete service.serviceItem;

        // if (serviceItem?.temp)
        //   services.push({
        //     ...service,
        //     items: [
        //       { quantity: 0, price: 0, temp: true, alias: serviceItem?.alias },
        //     ],
        //   });
        // else

        services.push({
          ...service,
          items: [{ ...serviceItem, quantity: 1 }],
        });
      }

      return {
        ...state,
        type: action.type,
        services,
        error: null,
      };
      break;
    }

    case ADD_SERVICE_ITEM_TO_CART_ERROR:
      return {
        ...state,
        ...action,
      };
      break;

    case REMOVE_SERVICE_ITEM_FROM_CART:
      return {
        ...state,
        ...action,
        error: null,
      };
      break;

    case REMOVE_SERVICE_ITEM_FROM_CART_SUCCESS: {
      let services = state.services;

      const serviceIndex = services.findIndex(
        (x) => x._id === action.payload._id
      );

      if (action.payload?.completeService) services.splice(serviceIndex, 1);
      else {
        let service = services[serviceIndex];
        let items = service.items;

        const serviceItemIndex = items.findIndex(
          (x) => x?._id === action.payload?.serviceItem?._id
        );

        let item = items[serviceItemIndex === -1 ? 0 : serviceItemIndex];

        // if (item?.temp) {
        //   services = services.filter((_, index) => index !== serviceIndex);
        // } else

        if (items.length === 1 && item.quantity === 1)
          services = services.filter((x) => x._id !== action.payload._id);
        //
        else if (item?.quantity === 1 || action.payload?.completeServiceItem) {
          //
          if (items.length === 1) {
            services = services.filter((x) => x._id !== action.payload._id);
          } else {
            items = items.filter(
              (x) => x?._id !== action.payload?.serviceItem?._id
            );
            service.items = items;
            services[serviceIndex] = service;
          }
          //
        } else {
          //
          items = items.map((x) =>
            x?._id === action.payload?.serviceItem?._id
              ? {
                  ...item,
                  quantity: item.quantity - 1,
                }
              : x
          );
          service.items = items;
          services[serviceIndex] = service;
        }
      }

      return {
        ...state,
        type: action.type,
        services,
        error: null,
      };
      break;
    }
    case REMOVE_SERVICE_ITEM_FROM_CART_ERROR:
      return {
        ...state,
        ...action,
      };
      break;

    case CART_ERROR_REMOVE:
      return {
        ...state,
        error: null,
      };
      break;

    case MARK_SERVICE_AS_SELECTED:
      const service = [...state.services];
      const option =
        service[action.payload.serviceIndex][action.payload.optionName];

      const optionIndex = option.findIndex(
        (item) => item._id === action.payload.optionId
      );

      for (let index = 0; index < option.length; index++) {
        option[index].is_selected = Boolean(index === optionIndex);
      }

      return { ...state, services: service };
      break;

    case CREATE_OR_UPDATE_CART_ID:
      return { ...state, cartId: action.payload?.cart_id || '' };
      break;

    case APPLY_COUPON_CODE:
      return { ...state, appliedCoupon: action.payload, couponCodeError: '' };
      break;

    case APPLY_COUPON_CODE_ERROR:
      return { ...state, couponCodeError: action.payload || '' };
      break;

    case UPDATE_TOTAL_PAYABLE:
      return { ...state, order_total: action.payload };
      break;

    case CLEAR_CART:
      return {
        collection: {
          day: '',
          time: '',
          driverInstructions: '',
        },
        delivery: [],
        services: [],
        selectedAddress: '',
        specialInstructions: '',
        pickUpAndDropOffPreference: '',
        frequency: '',
        error: null,
      };

    case RUSH_DELIVERY_OPTIONS:
      return { ...state, rushDeliveryOptions: action.payload };

    case UPDATE_SERVICE_NOTE:
      const serviceIndex = state.services.findIndex(
        (x) => x._id === action.payload.serviceId
      );
      if (serviceIndex !== -1) {
        return {
          ...state,
          services: [
            ...state.services.slice(0, serviceIndex),
            {
              ...state.services[serviceIndex],
              special_notes: action.payload.note,
            },
            ...state.services.slice(serviceIndex + 1),
          ],
        };
      }

    default:
      return state;
  }
};
