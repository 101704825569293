//Auth
export const LOGIN = 'login';
export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_ERROR = 'login_error';

export const REGISTER = 'register';
export const REGISTER_SUCCESS = 'register_success';
export const REGISTER_ERROR = 'register_error';

export const LOGOUT = 'logout';
export const LOGOUT_SUCCESS = 'logout_success';
export const LOGOUT_ERROR = 'logout_error';

export const FORGOT_PASSWORD = 'forgot_password';
export const FORGOT_PASSWORD_SUCCESS = 'forgot_password_success';
export const FORGOT_PASSWORD_ERROR = 'forgot_password_error';

export const RESET_PASSWORD = 'reset_password';
export const RESET_PASSWORD_SUCCESS = 'reset_password_success';
export const RESET_PASSWORD_ERROR = 'reset_password_error';

export const POST_FETCH_SUCCESS = 'POST_FETCH_SUCCESS';
export const POST_FETCH_MORE = 'POST_FETCH_MORE';

export const LAYOUT_FETCH_SUCCESS = 'LAYOUT_FETCH_SUCCESS';
export const LAYOUT_FETCH_MORE = 'LAYOUT_FETCH_MORE';

export const COMMENT_FETCH_SUCCESS = 'COMMENT_FETCH_SUCCESS';
export const COMMENT_CREATE_PENDING = 'COMMENT_CREATE_PENDING';
export const COMMENT_CREATE_FAILURE = 'COMMENT_CREATE_FAILURE';
export const COMMENT_CREATE_SUCCESS = 'COMMENT_CREATE_SUCCESS';

export const SEARCH_POSTS_SUCCESS = 'SEARCH_POSTS_SUCCESS';
export const SEARCH_POSTS_MORE = 'SEARCH_POSTS_MORE';
export const BOOKMARK_FETCH_SUCCESS = 'BOOKMARK_FETCH_SUCCESS';

export const BOOKMARK_CREATE_PENDING = 'BOOKMARK_CREATE_PENDING';
export const BOOKMARK_CREATE_SUCCESS = 'BOOKMARK_CREATE_SUCCESS';
export const BOOKMARK_CREATE_FAILURE = 'BOOKMARK_CREATE_FAILURE';

export const NOTIFICATION_FETCH_SUCCESS = 'NOTIFICATION_FETCH_SUCCESS';

//Address
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const SET_ADDRESS_ERRORS = 'SET_ADDRESS_ERRORS';
export const RESET_ADDRESS = 'RESET_ADDRESS';
export const ADD_ADDRESS = 'ADD_ADDRESS';
export const EDIT_ADDRESS = 'EDIT_ADDRESS';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';

//Delivery
export const UPDATE_Delivery = 'UPDATE_Delivery';
export const UPDATE_DROP_OFF = 'UPDATE_DROP_OFF';
export const DELETE_DROP_OFF = 'DELETE_DROP_OFF';
export const RESET_DELIVERY = 'RESET_DELIVERY';
export const SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS';
export const CLEAR_DELIVERY = 'CLEAR_DELIVERY';

// UserTblActions || UserTbl reducers
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
export const UPDATE_USERS = 'UPDATE_USERS';
export const UPDATE_USERS_SUCCESS = 'UPDATE_USERS_SUCCESS';
export const UPDATE_USERS_ERROR = 'UPDATE_USERS_ERROR';
export const ADD_USERS = 'ADD_USERS';
export const ADD_USERS_SUCCESS = 'ADD_USERS_SUCCESS';
export const ADD_USERS_ERROR = 'ADD_USERS_ERROR';
export const DELETE_USERS = 'DELETE_USERS';
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS';
export const DELETE_USERS_ERROR = 'DELETE_USERS_ERROR';
export const USERS_ERROR_REMOVE = 'USERS_ERROR_REMOVE';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const UPDATE_USER_WALLET_BALANCE = 'UPDATE_USER_WALLET_BALANCE';
export const UPDATE_USER_PROMOCODES = 'UPDATE_USER_PROMOCODES';
export const UPDATE_SELECTED_CITY = 'UPDATE_SELECTED_CITY';

//PromoCodes
export const FETCH_PROMO_CODES = 'FETCH_PROMO_CODES';
export const FETCH_PROMO_CODES_SUCCESS = 'FETCH_PROMO_CODES_SUCCESS';
export const FETCH_PROMO_CODES_ERROR = 'FETCH_PROMO_CODES_ERROR';
export const UPDATE_PROMO_CODES = 'UPDATE_PROMO_CODES';
export const UPDATE_PROMO_CODES_SUCCESS = 'UPDATE_PROMO_CODES_SUCCESS';
export const UPDATE_PROMO_CODES_ERROR = 'UPDATE_PROMO_CODES_ERROR';
export const ADD_PROMO_CODES = 'ADD_PROMO_CODES';
export const ADD_PROMO_CODES_SUCCESS = 'ADD_PROMO_CODES_SUCCESS';
export const ADD_PROMO_CODES_ERROR = 'ADD_PROMO_CODES_ERROR';
export const DELETE_PROMO_CODES = 'DELETE_PROMO_CODES';
export const DELETE_PROMO_CODES_SUCCESS = 'DELETE_PROMO_CODES_SUCCESS';
export const DELETE_PROMO_CODES_ERROR = 'DELETE_PROMO_CODES_ERROR';
export const PROMO_CODES_ERROR_REMOVE = 'PROMO_CODES_ERROR_REMOVE';

//banner

export const FETCH_BANNER = 'FETCH_BANNER';
export const FETCH_BANNER_SUCCESS = 'FETCH_BANNER_SUCCESS';
export const FETCH_BANNER_ERROR = 'FETCH_BANNER_ERROR';
export const ADD_BANNER = 'ADD_BANNER';
export const ADD_BANNER_SUCCESS = 'ADD_BANNER_SUCCESS';
export const ADD_BANNER_ERROR = 'ADD_BANNER_ERROR';
export const UPDATE_BANNER = 'UPDATE_BANNER';
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS';
export const UPDATE_BANNER_ERROR = 'UPDATE_BANNER_ERROR';
export const BANNER_ERROR_REMOVE = 'BANNER_ERROR_REMOVE';
export const DELETE_BANNER = 'DELETE_BANNER';
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS';
export const DELETE_BANNER_ERROR = 'DELETE_BANNER_ERROR';

//Services
export const FETCH_SERVICES = 'FETCH_SERVICES';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_ERROR = 'FETCH_SERVICES_ERROR';
export const UPDATE_SERVICES = 'UPDATE_SERVICES';
export const UPDATE_SERVICES_SUCCESS = 'UPDATE_SERVICES_SUCCESS';
export const UPDATE_SERVICES_ERROR = 'UPDATE_SERVICES_ERROR';
export const ADD_SERVICES = 'ADD_SERVICES';
export const ADD_SERVICES_SUCCESS = 'ADD_SERVICES_SUCCESS';
export const ADD_SERVICES_ERROR = 'ADD_SERVICES_ERROR';
export const DELETE_SERVICES = 'DELETE_SERVICES';
export const DELETE_SERVICES_SUCCESS = 'DELETE_SERVICES_SUCCESS';
export const DELETE_SERVICES_ERROR = 'DELETE_SERVICES_ERROR';
export const SERVICES_ERROR_REMOVE = 'SERVICES_ERROR_REMOVE';

//Cart

export const ADD_SERVICE_ITEM_TO_CART = 'ADD_SERVICE_ITEM_TO_CART';
export const ADD_SERVICE_ITEM_TO_CART_SUCCESS =
  'ADD_SERVICE_ITEM_TO_CART_SUCCESS';
export const ADD_SERVICE_ITEM_TO_CART_ERROR = 'ADD_SERVICE_ITEM_TO_CART_ERROR';
export const REMOVE_SERVICE_ITEM_FROM_CART = 'REMOVE_SERVICE_ITEM_FROM_CART';
export const REMOVE_SERVICE_ITEM_FROM_CART_SUCCESS =
  'REMOVE_SERVICE_ITEM_FROM_CART_SUCCESS';
export const REMOVE_SERVICE_ITEM_FROM_CART_ERROR =
  'REMOVE_SERVICE_ITEM_FROM_CART_ERROR';
export const CART_ERROR_REMOVE = 'CART_ERROR_REMOVE';
export const MARK_SERVICE_AS_SELECTED = 'MARK_SERVICE_AS_SELECTED';
export const CREATE_OR_UPDATE_CART_ID = 'CREATE_OR_UPDATE_CART_ID';
export const APPLY_COUPON_CODE = 'APPLY_COUPON_CODE';
export const APPLY_COUPON_CODE_ERROR = 'APPLY_COUPON_CODE_ERROR';
export const UPDATE_TOTAL_PAYABLE = 'UPDATE_TOTAL_PAYABLE';
export const CLEAR_CART = 'CLEAR_CART';
export const RUSH_DELIVERY_OPTIONS = 'RUSH_DELIVERY_OPTIONS';
export const UPDATE_SERVICE_NOTE = 'UPDATE_SERVICE_NOTE';

//Customers
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_ERROR = 'FETCH_CUSTOMERS_ERROR';
export const UPDATE_CUSTOMERS = 'UPDATE_CUSTOMERS';
export const UPDATE_CUSTOMERS_SUCCESS = 'UPDATE_CUSTOMERS_SUCCESS';
export const UPDATE_CUSTOMERS_ERROR = 'UPDATE_CUSTOMERS_ERROR';
export const ADD_CUSTOMERS = 'ADD_CUSTOMERS';
export const ADD_CUSTOMERS_SUCCESS = 'ADD_CUSTOMERS_SUCCESS';
export const ADD_CUSTOMERS_ERROR = 'ADD_CUSTOMERS_ERROR';
export const DELETE_CUSTOMERS = 'DELETE_CUSTOMERS';
export const DELETE_CUSTOMERS_SUCCESS = 'DELETE_CUSTOMERS_SUCCESS';
export const DELETE_CUSTOMERS_ERROR = 'DELETE_CUSTOMERS_ERROR';
export const CUSTOMERS_ERROR_REMOVE = 'CUSTOMERS_ERROR_REMOVE';
export const CUSTOMERS_UPDATE_COLUMNS = 'CUSTOMERS_UPDATE_COLUMNS';
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_ERROR = 'SEND_NOTIFICATION_ERROR';
export const CUSTOMER_TIME_ZONE = 'CUSTOMER_TIME_ZONE';

//Wallets
export const FETCH_WALLETS = 'FETCH_WALLETS';
export const FETCH_WALLETS_SUCCESS = 'FETCH_WALLETS_SUCCESS';
export const FETCH_WALLETS_ERROR = 'FETCH_WALLETS_ERROR';
export const UPDATE_WALLETS = 'UPDATE_WALLETS';
export const UPDATE_WALLETS_SUCCESS = 'UPDATE_WALLETS_SUCCESS';
export const UPDATE_WALLETS_ERROR = 'UPDATE_WALLETS_ERROR';
export const CREATE_WALLETS = 'CREATE_WALLETS';
export const CREATE_WALLETS_SUCCESS = 'CREATE_WALLETS_SUCCESS';
export const CREATE_WALLETS_ERROR = 'CREATE_WALLETS_ERROR';
export const WALLETS_ERROR_REMOVE = 'WALLETS_ERROR_REMOVE';
export const WALLETS_UPDATE_COLUMNS = 'WALLETS_UPDATE_COLUMNS';

//Orders
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR';
export const UPDATE_ORDERS = 'UPDATE_ORDERS';
export const UPDATE_ORDERS_SUCCESS = 'UPDATE_ORDERS_SUCCESS';
export const UPDATE_ORDERS_ERROR = 'UPDATE_ORDERS_ERROR';
export const ADD_ORDERS = 'ADD_ORDERS';
export const ADD_ORDERS_SUCCESS = 'ADD_ORDERS_SUCCESS';
export const ADD_ORDERS_ERROR = 'ADD_ORDERS_ERROR';
export const DELETE_ORDERS = 'DELETE_ORDERS';
export const DELETE_ORDERS_SUCCESS = 'DELETE_ORDERS_SUCCESS';
export const DELETE_ORDERS_ERROR = 'DELETE_ORDERS_ERROR';
export const ORDERS_ERROR_REMOVE = 'ORDERS_ERROR_REMOVE';
export const ORDERS_UPDATE_COLUMNS = 'ORDERS_UPDATE_COLUMNS';
export const UPDATE_ORDERS_ADDRESS = 'UPDATE_ORDERS_ADDRESS';
export const UPDATE_ORDERS_ADDRESS_SUCCESS = 'UPDATE_ORDERS_ADDRESS_SUCCESS';
export const UPDATE_ORDERS_ADDRESS_ERROR = 'UPDATE_ORDERS_ADDRESS_ERROR';

//fetch card
export const FETCH_CARDS = 'FETCH_CARDS';
export const FETCH_CARDS_SUCCESS = 'FETCH_CARDS_SUCCESS';
export const FETCH_CARDS_ERROR = 'FETCH_CARDS_ERROR';

export const ADD_CARDS = 'ADD_CARDS';
export const ADD_CARDS_SUCCESS = 'ADD_CARDS_SUCCESS';
export const ADD_CARDS_ERROR = 'ADD_CARDS_ERROR';

export const CHARGE_CARDS = 'CHARGE_CARDS';
export const CHARGE_CARDS_SUCCESS = 'CHARGE_CARDS_SUCCESS';
export const CHARGE_CARDS_ERROR = 'CHARGE_CARDS_ERROR';

export const REFUND_CHARGE = 'REFUND_CHARGE';
export const REFUND_CHARGE_SUCCESS = 'REFUND_CHARGE_SUCCESS';
export const REFUND_CHARGE_ERROR = 'REFUND_CHARGE_ERROR';

export const TIMELINE = 'TIMELINE';
export const TIMELINE_SUCCESS = 'TIMELINE_SUCCESS';
export const TIMELINE_ERROR = 'TIMELINE_ERROR';

export const APPLYPROMOCODE_ORDERS_SERVICE = 'APPLYPROMOCODE_ORDERS_SERVICE';
export const APPLYPROMOCODE_ORDERS_SERVICE_ERROR =
  'APPLYPROMOCODE_ORDERS_SERVICE_ERROR';
export const APPLYPROMOCODE_ORDERS_SERVICE_SUCCESS =
  'APPLYPROMOCODE_ORDERS_SERVICE_SUCCESS';

export const APPLY_WALLETPOINT_ORDER = 'APPLY_WALLETPOINT_ORDER';
export const APPLY_WALLETPOINT_ORDER_ERROR = 'APPLY_WALLETPOINT_ORDER_ERROR';
export const APPLY_WALLETPOINT_ORDER_SUCCESS =
  'APPLY_WALLETPOINT_ORDER_SUCCESS';

export const FETCH_CUSTOMER_WALLETS_POINTS = 'FETCH_CUSTOMER_WALLETS_POINTS';
export const FETCH_CUSTOMER_WALLETS_POINTS_SUCCESS =
  'FETCH_CUSTOMER_WALLETS_POINTS_SUCCESS';
export const FETCH_CUSTOMER_WALLETS_POINTS_ERROR =
  'FETCH_CUSTOMER_WALLETS_POINTS_ERROR';

export const REMOVE_WALLETPOINT_ORDER = 'REMOVE_WALLETPOINT_ORDER';
export const REMOVE_WALLETPOINT_ORDER_ERROR = 'REMOVE_WALLETPOINT_ORDER_ERROR';
export const REMOVE_WALLETPOINT_ORDER_SUCCESS =
  'REMOVE_WALLETPOINT_ORDER_SUCCESS';

export const DELETE_ORDERS_SERVICE = 'DELETE_ORDERS_SERVICE';
export const DELETE_ORDERS_SERVICE_ERROR = 'DELETE_ORDERS_SERVICE_ERROR';
export const DELETE_ORDERS_SERVICE_SUCCESS = 'DELETE_ORDERS_SERVICE_SUCCESS';
//Serviceable Regions
export const FETCH_SERVICEABLE_REGIONS = 'FETCH_SERVICEABLE_REGIONS';
export const FETCH_SERVICEABLE_REGIONS_SUCCESS =
  'FETCH_SERVICEABLE_REGIONS_SUCCESS';
export const FETCH_SERVICEABLE_REGIONS_ERROR =
  'FETCH_SERVICEABLE_REGIONS_ERROR';
export const UPDATE_SERVICEABLE_REGIONS = 'UPDATE_SERVICEABLE_REGIONS';
export const UPDATE_SERVICEABLE_REGIONS_SUCCESS =
  'UPDATE_SERVICEABLE_REGIONS_SUCCESS';
export const UPDATE_SERVICEABLE_REGIONS_ERROR =
  'UPDATE_SERVICEABLE_REGIONS_ERROR';
export const ADD_SERVICEABLE_REGIONS = 'ADD_SERVICEABLE_REGIONS';
export const ADD_SERVICEABLE_REGIONS_SUCCESS =
  'ADD_SERVICEABLE_REGIONS_SUCCESS';
export const ADD_SERVICEABLE_REGIONS_ERROR = 'ADD_SERVICEABLE_REGIONS_ERROR';
export const DELETE_SERVICEABLE_REGIONS = 'DELETE_SERVICEABLE_REGIONS';
export const DELETE_SERVICEABLE_REGIONS_SUCCESS =
  'DELETE_SERVICEABLE_REGIONS_SUCCESS';
export const DELETE_SERVICEABLE_REGIONS_ERROR =
  'DELETE_SERVICEABLE_REGIONS_ERROR';
export const SERVICEABLE_REGIONS_ERROR_REMOVE =
  'DELETE_SERVICEABLE_REGIONS_ERROR_REMOVE';

//Common for all
export const FETCH_PENDING = 'FETCH_PENDING';

export const FETCH_CONFIG = 'FETCH_CONFIG';
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS';
export const FETCH_CONFIG_ERROR = 'FETCH_CONFIG_ERROR';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const UPDATE_CONFIG_SUCCESS = 'UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_ERROR = 'UPDATE_CONFIG_ERROR';
export const CONFIG_ERROR_REMOVE = 'CONFIG_ERROR_REMOVE';

export const RSUH_DELIVERY = 'RSUH_DELIVERY';
