/**
 * Created by admin1 on 26/10/18.
 */
import { combineReducers } from "redux";
import { userApp } from "./UserReducer";
import serviceReducer from "./ServicesReducer";
import cartReducer from "./CartReducer";
import addressReducer from "./AddressReducer";
import deliveryReducer from "./deliveryReducer";

export default combineReducers({
  user: userApp,
  services: serviceReducer,
  cart: cartReducer,
  address: addressReducer,
  delivery: deliveryReducer,
});
