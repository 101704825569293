import _ from "lodash";

export default class StorageService {
  static save(key, data) {
    if (data) {
      delete data.password;
      delete data.password_confirmation;
    }

    if (_.isPlainObject(data)) {
      data.timestamp = new Date().getTime() / 1000;
      localStorage.setItem(key, JSON.stringify(data));
    } else {
      localStorage.setItem(key, data);
    }
  }

  static remove(key) {
    localStorage.removeItem(key);
  }

  static get(key) {
    let obj = localStorage.getItem(key);
    if (obj !== "undefined") {
      /*let data = JSON.parse(obj);
            let now = new Date().getTime()/1000;

            if(data && (!data.timestamp || (now - data.timestamp > 1800))){
                localStorage.setItem(key, null);
                return null;
            }*/
      return obj;
    }
    return null;
  }

  static clearAll() {
    localStorage.clear();
  }
}
