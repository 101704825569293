import {
  UPDATE_Delivery,
  RESET_DELIVERY,
  SET_DELIVERY_ADDRESS,
  UPDATE_DROP_OFF,
  DELETE_DROP_OFF,
  CLEAR_DELIVERY,
} from '../types';

const initialState = {
  collection: {
    day: '',
    time: '',
    driverInstructions: '',
  },
  delivery: [],
  selectedAddress: '',
  specialInstructions: '',
  pickUpAndDropOffPreference: '',
  frequency: '',
  error: null,
};

const setProperty = (obj, path, value) => {
  const [head, ...rest] = path.split('.');
  return {
    ...obj,
    [head]: rest.length ? setProperty(obj[head], rest.join('.'), value) : value,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_Delivery:
      {
        return setProperty(state, action.payload.key, action.payload.value);
      }
      break;
    case RESET_DELIVERY:
      {
        return initialState;
      }
      break;
    case SET_DELIVERY_ADDRESS:
      {
        return { ...state, selectedAddress: action.payload };
      }
      break;
    case UPDATE_DROP_OFF:
      {
        const serviceIndex = state.delivery.findIndex(
          (delivery) => delivery.groupName === action.payload.groupName
        );
        if (serviceIndex === -1)
          return {
            ...state,
            delivery: [
              ...state.delivery,
              { day: '', time: '', ...action.payload },
            ],
          };
        return {
          ...state,
          delivery: [
            ...state.delivery.slice(0, serviceIndex),
            { ...state.delivery[serviceIndex], ...action.payload },
            ...state.delivery.slice(serviceIndex + 1),
          ],
        };
      }
      break;

    case DELETE_DROP_OFF: {
      return {
        ...state,
        delivery: state.delivery.filter(
          (delivery) => delivery.groupName === action.payload
        ),
      };
    }

    case CLEAR_DELIVERY:
      return {
        collection: {
          day: '',
          time: '',
          driverInstructions: '',
        },
        delivery: [],
        selectedAddress: '',
        specialInstructions: '',
        pickUpAndDropOffPreference: '',
        frequency: '',
        error: null,
      };

    default:
      return state;
  }
};
