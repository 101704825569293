const axios = require('axios').default;

//apply base url for axios
//export const API_URL = "http://localhost:3001";
// export const API_URL = 'https://nimapi.beelive.in/';
export const API_URL = 'https://api.getnimnim.tech';
export const PUBLIC_URL = 'http://localhost:3000';

// export const API_URL = "https://nimapi.beelive.in";
// export const PUBLIC_URL = "https://nimnim.beelive.in";

const axiosApiInstance = axios.create({
  baseURL: API_URL,
});

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    if (localStorage.getItem('token')) {
      config.headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };
    }

    const CancelToken = axios.CancelToken;

    return {
      ...config,
      CancelToken: new CancelToken((cancel) =>
        cancel('Cancel repeated request')
      ),
    };
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    if (error.response && error.response.status === 403) {
      // redirect to 403 page
      window.location = '/home';
    }
    return Promise.reject(error);
  }
);

export default axiosApiInstance;
